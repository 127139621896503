import React from 'react'

const Details = ({ heading, content }) => {
    return (
      <div className="option-details">
        <h2>{heading}</h2>
        <p>{content}</p>
      </div>
    );
  };
export default Details
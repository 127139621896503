

import React from 'react';


import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';


import './Footer.css';

const Footer = () => {

  

  return (
    <div className="app__footer section__padding" id='footer'>

      <div className="app__footer-links">
        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">Contact Us</h1>
          <p className="p__opensans1">221, Midas Square, Parvat Gam, Surat, Gujarat, India - 395010</p>
          <p className="p__opensans1">+91 8824284772</p>
        </div>

        <div className="app__footer-links_logo">
          <p className="p__opensans1">
            "The best way to find yourself is to lose yourself in the service of others.”
          </p>
          <div className="app__footer-links_icons">
            <FiFacebook />
            <FiTwitter />
            <FiInstagram />
          </div>
        </div>

        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">Working Hours</h1>
          <p className="p__opensans1">Monday - Friday</p>
          <p className="p__opensans1">08:00 am -12:00 am</p>
          <p className="p__opensans1">Saturday - Sunday</p>
          <p className="p__opensans1">07:00am -11:00 pm</p>
        </div>

      </div>
      <div className="footer__copyright">
        <p className="p__opensans1">2023 TRAVELWINGS. All Rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Hotels.css";
import HotelImg from "./image";
import cities from "./cities";
import budget from "./budget";
import Header from "../header/Header";
import Offers from "../offers/Offers";
import Whyus from "../whyus/Whyus";
import Services from "../services/Services";

import link from '../../apiCall';

const Hotels = () => {
  const [location, setLocation] = useState(null);
  const [money, setMoney] = useState(null);


  const handleLocation = (selectedOption) => {
    setLocation(selectedOption);
  };
  const handleMoneyCity = (selectedOption) => {
    setMoney(selectedOption);
  };

  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(null);
  const currentDate = new Date();

  const handleCheckInChange = (date) => {
    setCheckInDate(date);

  };
  const handleCheckOutDate = (date) => {
    setCheckOutDate(date);
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "168px",
      border: "none",
      cursor: "pointer",
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };

  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleLocationDropdown = () => {
    setIsLocationDropdownOpen(!isLocationDropdownOpen);
  };


  const [isMoneyDropdownOpen, setIsMoneyDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleMoneyDropdown = () => {
    setIsMoneyDropdownOpen(!isMoneyDropdownOpen);
  };
  //--------------------

  const [rooms, setRooms] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  //----------------------
  const [roomsError, setRoomsError] = useState('');
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateRooms = () => {
    const parsedValue = parseInt(rooms, 10);

    if (isNaN(parsedValue) || parsedValue < 1 || parsedValue >= 5) {
      setRoomsError('Please enter a valid number between 1 and 5');
    } else {
      setRoomsError('');
    }
  };

  const validatePhone = () => {
    const phoneRegex = /^\d{10}$/;

    if (!phone.match(phoneRegex)) {
      setPhoneError("Please enter a valid 10-digit mobile number");
    } else {
      setPhoneError("");
    }
  };

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("Please enter your name");
    } else {
      setNameError("");
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.match(emailRegex)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };


  //---------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateRooms();
    validatePhone();
    validateName();
    validateEmail();

    const checkIn = checkInDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });
    const checkOut = checkOutDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });

    console.log(JSON.stringify({
      location,
      rooms,
      checkIn,
      checkOut,
      phone,
      money,
      name,
      email
    }));

    try {
      const response = await fetch(`${link}/hotels`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          location,
          rooms,
          checkIn,
          checkOut,
          phone,
          money,
          name,
          email
        })
      });
      console.log(response);
      const data = await response.json()
      if (response.status === 400 || !response) {
        toast.error(data.message)
        console.log("No Registered")
      }
      else {
        toast.success(data.message)
        console.log("Yes Registered")
      }
    }
    catch (error) {
      console.error("Error during form submission:", error);
      toast.error("An error occurred during submission.");
    }
    finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <div className="tw__hotels">
        <Header quote="Experience the world's accommodations, tailored to your preferences, all in one place!" />
        <div className="image_hotels_container">
          <img src={HotelImg.hotel} alt="No Plane" />
        </div>
        <div className="tw__hotels_form">
          <form onSubmit={handleSubmit} className="tw__hotels_form-container">
            <ToastContainer />
            <div className="tw__hotels_form_inputs">
              <div className="location" onClick={toggleLocationDropdown}>
                <label className="label_hotel">Location</label>
                <Select
                  className="tw__select_city"
                  value={location}
                  onChange={handleLocation}
                  options={cities}
                  placeholder="Click to select City"
                  styles={customStyles}
                  menuIsOpen={isLocationDropdownOpen}
                  onClick={toggleLocationDropdown}
                />
              </div>

              <div className="room_hotels">
                <label className="label_hotel">Rooms</label>
                <input
                  type="text"
                  name="rooms"
                  id="rooms"
                  placeholder="1"
                  value={rooms}
                  onChange={(e) => setRooms(e.target.value)}
                  onBlur={validateRooms}
                  className={roomsError ? 'errorRooms' : ''}
                  autoComplete="off"
                />
                {roomsError && <p className="error-messageRooms">{roomsError}</p>}
              </div>


              <div className="date_departure_hotels">
                <label className="label_hotel">Chech-In</label>
                <DatePicker
                  className="date-picker_hotels"
                  selected={checkInDate}
                  onChange={handleCheckInChange}
                  minDate={currentDate}
                  customInput={
                    <div className="custom-input1_hotels">
                      <div className="date_hotels">
                        {checkInDate && checkInDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric' })}
                      </div>
                      <div className="day_hotels">
                        {checkInDate && checkInDate.toLocaleDateString('default', { weekday: 'short' })}
                      </div>
                    </div>
                  }
                />
              </div>


              <div className="date_return_hotels">
                <label className="label_hotel">Check-Out</label>
                <DatePicker
                  className="date-picker_hotels"
                  selected={checkOutDate}
                  onChange={handleCheckOutDate}
                  dateFormat="dd/MM/yyyy"
                  minDate={currentDate}
                  customInput={
                    <div className="custom-input2_hotels">
                      {checkOutDate ? (
                        <React.Fragment>
                          <div className="date_hotels">
                            {checkOutDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric' })}
                          </div>
                          <div className="day_hotels">
                            {checkOutDate.toLocaleDateString('default', { weekday: 'short' })}
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="placeholder_hotels">
                          Tap to enter Date
                        </div>
                      )}
                    </div>
                  }
                // placeholderText="Click to enter Date"
                />
              </div>


              <div className="phone_hotels">
                <label className="label_hotel">Mobile No.</label>
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="999999999"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onBlur={validatePhone}
                  className={phoneError ? "errorPhone" : ""}
                />
                {phoneError && <p className="error-messagePhone">{phoneError}</p>}
              </div>

              <div className="budget_hotels" onClick={toggleMoneyDropdown}>
                <label className="label_hotel">Budget</label>
                <Select
                  className="tw__select_budget"
                  value={money}
                  onChange={handleMoneyCity}
                  options={budget}
                  placeholder="Select the Budget"
                  styles={customStyles}
                  menuIsOpen={isMoneyDropdownOpen}
                  onClick={toggleMoneyDropdown}
                />

              </div>
              <div className="name_hotels">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your Name Here"
                  value={name}
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                  onBlur={validateName}
                  className={nameError ? "errorName" : ""}
                />
                {nameError && <p className="error-messageName">{nameError}</p>}
              </div>
              <div className="email_hotels">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email Here"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={validateEmail}
                  className={emailError ? "errorName" : ""}
                  autoComplete="off"
                />
                {emailError && <p className="error-messageName">{emailError}</p>}
              </div>
            </div>
            <div className="btn_hotels">
              <button
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Offers />
      <Whyus />
      <Services />
    </>
  );
};

export default Hotels;
import React, { useState } from 'react';
import Details from './Details';
import options from './data'
import './Services.css'

const OptionSection = () => {
  const [selectedOption, setSelectedOption] = useState(
    options[0] ? options[0].id : null
  );

  const handleOptionClick = (optionId) => {
    setSelectedOption(optionId);
  };

  const selectedOptionDetails = options.find(
    (option) => option.id === selectedOption
  );

  return (
    <div className="tw__services">
      <div className="heading">
        <h1>Services</h1>
      </div>
      <div className="tw__services_section">
        <div className="tw__services_options">
          {options.map((option) => (
            <button
              key={option.id}
              onClick={() => handleOptionClick(option.id)}
              className={selectedOption === option.id ? 'active-button' : ''}
            >
              {option.heading}
              
            </button>
          ))}
        </div>
        <div className="tw__services_information">
          <Details
            heading={selectedOptionDetails.heading}
            content={selectedOptionDetails.content}
          />
        </div>
      </div>
    </div>
  );
};

export default OptionSection;
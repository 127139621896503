import React from 'react'
import './whyus.css'
import img1 from '../../assets/whyusImg/img1.svg'
import img2 from '../../assets/whyusImg/img2.svg'
import img3 from '../../assets/whyusImg/img3.svg'
import img4 from '../../assets/whyusImg/img4.svg'
const Whyus = () => {
	return (
		<div className='app__whyus section__padding '>
			<div className="app__whyus-header">
				<h3 className="headtext">Why Book with Travel Wings!</h3>
			</div>
			<div className="app__whyus-boxcontainer">
				<div className='app__whyus-boxcontainer-first'>
					<div className="app__whyus-boxcontainer_box">
						<div className="app__whyus-boxcontainer_box-img"><img src={img1} alt="Easy Booking" /></div>
						<h3 className="app__whyus-boxcontainer_box-title">Easy Booking</h3>
						<p className="app__whyus-boxcontainer_box-para p__opensans">We offer easy and convenient flight bookings with attractive offers.</p>
					</div>
					<div className="app__whyus-boxcontainer_box">
						<div className="app__whyus-boxcontainer_box-img"><img src={img2} alt="Lowest Price" /></div>
						<h3 className="app__whyus-boxcontainer_box-title">Lowest Price</h3>
						<p className="app__whyus-boxcontainer_box-para p__opensans">We ensure low rates on hotel reservation, holiday packages and on flight tickets.</p>
					</div>
				</div>
				<div className='app__whyus-boxcontainer-second'>
					<div className="app__whyus-boxcontainer_box">
						<div className="app__whyus-boxcontainer_box-img"><img src={img3} alt="Exciting Deals" /></div>
						<h3 className="app__whyus-boxcontainer_box-title">Exciting Deals</h3>
						<p className="app__whyus-boxcontainer_box-para p__opensans">Enjoy exciting deals on flights, hotels, buses, car rental and holiday packages.</p>
					</div>
					<div className="app__whyus-boxcontainer_box">
						<div className="app__whyus-boxcontainer_box-img"><img src={img4} alt="24/7 Support" /></div>
						<h3 className="app__whyus-boxcontainer_box-title">Immediate Support</h3>
						<p className="app__whyus-boxcontainer_box-para p__opensans">Get immediate assistance on any kind of travel related query. We are happy to assist you.</p>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Whyus

import React from "react";
import Navbar from "../components/navbar/Navbar";
// import Home from "../components/home/Home";
import Booking from '../components/booking/Booking'
import Footer from "../components/footer/Footer";
import About from '../components/aboutus/Aboutus'
import Contact from '../components/contactus/Contactus'
import Hotels from "../components/hotels/Hotels";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Vacations from "../components/vacations/Vacations";


const RoutesComponent = () => {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Booking />} />
          <Route exact path="/hotels" element={<Hotels />} />
          <Route exact path="/vacations" element={<Vacations />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        
        <Footer />
      </Router>
    </div>
  );
};

export default RoutesComponent;

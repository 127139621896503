import React, { useState } from "react";
import "./navbar.css";
import { FaBars } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import logo from "../../assets/navbarImg/logo.PNG";
import title from "../../assets/navbarImg/title.PNG";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="app__navbar">
      <Link to="/">
        <div className="app__navbar-logo">
          <img src={logo} alt="logo" />
          <img src={title} alt="TRAVEL WINGS" />
        </div>
      </Link>
      <ul className="app__navbar-links">

        <li className="p__opensans">
          <Link to="/">Booking</Link>
        </li>
        <li className="p__opensans">
          <Link to="/hotels">Hotels</Link>
        </li>
        <li className="p__opensans">
          <Link to="/vacations">Vacations</Link>
        </li>

      </ul>

      <div className="app__navbar-contacts">
        <Link className="p__opensans" to="/contact">
          Contact Us
        </Link>
        <Link className="p__opensans" to="/about">
          About Us
        </Link>
      </div>

      <div className="app__navbar-smallscreen">
        <FaBars
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <AiOutlineClose
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />

            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <Link to="/">Booking</Link>
              </li>

              <li className="p__opensans">
                <Link to="/hotels">Hotels</Link>
              </li>
              <li className="p__opensans">
                <Link to="/vacations">Vacations</Link>
              </li>
            </ul>
            <div className="app__navbar-smallscreen_contacts">
              <li className="p__opensans">
              <Link to="/contact">Contact Us</Link>
              </li>
              <li className="p__opensans">
              <Link to="/about">About Us</Link>
              </li>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

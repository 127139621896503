import React from "react";
import Article from "../article/Article";
import img1 from "../../assets/packageImg/thailand.png";
import img2 from "../../assets/packageImg/eifeltower.png";
import img3 from "../../assets/packageImg/bali.png";
import img4 from "../../assets/packageImg/dubai.png";
// import img2 from "../../assets/packageImg/img2.jpeg";
import './packageoffer.css'

const Packageoffers = () => {
  return (
    <div className="gpt3__blog section__padding" id="blog">
      <div className="app_poffers-header">
          <h3 className="headtext">Popular Packages</h3>
        </div>
      <div className="gpt3__blog-container">
        <div className="gpt3__blog-container_groupB">
          <Article
            imgUrl={img1}
            Place="Thailand"
            days={4}
            night={5}
            />
          <Article
            imgUrl={img2}
            Place="Europe"
            days={10}
            night={9}
          />
          <Article
            imgUrl={img3}
            Place="Bali"
            days={6}
            night={7}
          />
          <Article
            imgUrl={img4}
            Place="Dubai"
            days={4}
            night={5}
          />
        </div>
      </div>
    </div>
  );
};

export default Packageoffers;

import React from 'react'
import HeaderImg from './image'

import './Header.css'
const Header = ({ quote }) => {
    return (
        <div className="quote_container">
            <img src={HeaderImg.quote} alt="No Quote" />
            <p>{quote}</p>
        </div>
    )
}

export default Header
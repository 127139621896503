const cities = [
    { value: "Others", label: "Others" },
    { value: "Delhi", label: "Delhi (DEL)" },
    { value: "Mumbai", label: "Mumbai (BOM)" },
    { value: "Bangalore", label: "Bangalore (BLR)" },
    { value: "Chennai", label: "Chennai (MAA)" },
    { value: "Kolkata", label: "Kolkata (CCU)" },
    { value: "Hyderabad", label: "Hyderabad (HYD)" },
    { value: "Pune", label: "Pune (PNQ)" },
    { value: "Ahmedabad", label: "Ahmedabad (AMD)" },
    { value: "Jaipur", label: "Jaipur (JAI)" },
    { value: "Lucknow", label: "Lucknow (LKO)" },
    { value: "Kochi", label: "Kochi (COK)" },
    { value: "Goa", label: "Goa (GOI)" },
    { value: "Chandigarh", label: "Chandigarh (IXC)" },
    { value: "Bhubaneswar", label: "Bhubaneswar (BBI)" },
    { value: "Guwahati", label: "Guwahati (GAU)" },
    { value: "Indore", label: "Indore (IDR)" },
    { value: "Vadodara", label: "Vadodara (BDQ)" },
    { value: "Amritsar", label: "Amritsar (ATQ)" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram (TRV)" },
    { value: "Coimbatore", label: "Coimbatore (CJB)" },
    { value: "Jammu", label: "Jammu (IXJ)" },
    { value: "Srinagar", label: "Srinagar (SXR)" },
    { value: "Dehradun", label: "Dehradun (DED)" },
    { value: "Shimla", label: "Shimla (SLV)" },
    { value: "Raipur", label: "Raipur (RPR)" },
    { value: "Patna", label: "Patna (PAT)" },
    { value: "Ranchi", label: "Ranchi (IXR)" },
    { value: "Bhopal", label: "Bhopal (BHO)" },
    { value: "Nagpur", label: "Nagpur (NAG)" },
    { value: "Surat", label: "Surat (STV)" },
    { value: "Visakhapatnam", label: "Visakhapatnam (VTZ)" },
    { value: "Trichy", label: "Trichy (TRZ)" },
    { value: "Madurai", label: "Madurai (IXM)" },
    { value: "Vijayawada", label: "Vijayawada (VGA)" },
    { value: "Cochin", label: "Cochin (COK)" },
    { value: "Agra", label: "Agra (AGR)" },
    { value: "Leh", label: "Leh (IXL)" },
    { value: "Jodhpur", label: "Jodhpur (JDH)" },
    { value: "Dibrugarh", label: "Dibrugarh (DIB)" },
    { value: "Jaisalmer", label: "Jaisalmer (JSA)" },
    { value: "Agatti", label: "Agatti (AGX)" },
    { value: "Kullu", label: "Kullu (KUU)" },
    { value: "Ladakh", label: "Ladakh (IXL)" },
    { value: "Shillong", label: "Shillong (SHL)" },
    { value: "Port Blair", label: "Port Blair (IXZ)" },
    { value: "Daman", label: "Daman (NMB)" },
    { value: "Bhavnagar", label: "Bhavnagar (BHU)" },
    { value: "Gorakhpur", label: "Gorakhpur (GOP)" },
    { value: "Jamnagar", label: "Jamnagar (JGA)" },
    { value: "Tezpur", label: "Tezpur (TEZ)" },
    { value: "Kannur", label: "Kannur (CNN)" },
    { value: "Lilabari", label: "Lilabari (IXI)" },
    { value: "Dharamshala", label: "Dharamshala (DHM)" },
    { value: "Kadapa", label: "Kadapa (CDP)" },
    { value: "Mysore", label: "Mysore (MYQ)" },
    { value: "Salem", label: "Salem (SXV)" },
    { value: "Shirdi", label: "Shirdi (SAG)" },
    { value: "Tirupati", label: "Tirupati (TIR)" },
    { value: "Rajahmundry", label: "Rajahmundry (RJA)" },
    { value: "Rupsi", label: "Rupsi (RUP)" },
    { value: "Kishangarh", label: "Kishangarh (KQH)" },
    { value: "Nashik", label: "Nashik (ISK)" },
    { value: "Kanpur", label: "Kanpur (KNU)" },
    { value: "Hubli", label: "Hubli (HBX)" },
    { value: "Jharsuguda", label: "Jharsuguda (JRG)" },
    { value: "Bhatinda", label: "Bhatinda (BUP)" },
    { value: "Belgaum", label: "Belgaum (IXG)" },
    { value: "Darbhanga", label: "Darbhanga (DBR)" },
    { value: "Silchar", label: "Silchar (IXS)" },
    { value: "Latur", label: "Latur (LTU)" },
    { value: "Mysore", label: "Mysore (MYQ)" },
    { value: "Nanded", label: "Nanded (NDC)" },
    { value: "Pakyong", label: "Pakyong (PYG)" },
    { value: "Pasighat", label: "Pasighat (IXT)" },
    { value: "Pithoragarh", label: "Pithoragarh (PGH)" },
    { value: "Shimla", label: "Shimla (SLV)" },
    { value: "Kannur", label: "Kannur (CNN)" },
    { value: "Gwalior", label: "Gwalior (GWL)" },
    { value: "Kandla", label: "Kandla (IXY)" },
    { value: "Porbandar", label: "Porbandar (PBD)" },
    { value: "Bhuntar", label: "Bhuntar (KUU)" },
    { value: "Panna", label: "Panna (PNY)" },
    { value: "Birsa Munda", label: "Birsa Munda (IXR)" },
    { value: "Jharsuguda", label: "Jharsuguda (JRG)" },
    { value: "Bhuj", label: "Bhuj (BHJ)" },
    { value: "Kangra", label: "Kangra (DHM)" },
    { value: "Allahabad", label: "Allahabad (IXD)" },
    { value: "Jabalpur", label: "Jabalpur (JLR)" },
    { value: "Nagaur", label: "Nagaur (NAG)" },
    { value: "Jamshedpur", label: "Jamshedpur (IXW)" },
    { value: "Vidyanagar", label: "Vidyanagar (VDY)" },
    { value: "Sindhudurg", label: "Sindhudurg (SDR)" },
    { value: "Bilaspur", label: "Bilaspur (PAB)" },
    { value: "Gondia", label: "Gondia (GDA)" },
    { value: "Jorhat", label: "Jorhat (JRH)" },
    { value: "Jagdalpur", label: "Jagdalpur (JGB)" },
    { value: "Kalaburagi", label: "Kalaburagi (GBI)" },
    { value: "Kolhapur", label: "Kolhapur (KLH)" },
    { value: "Ludhiana", label: "Ludhiana (LUH)" },
    { value: "Neyveli", label: "Neyveli (NVY)" },
    { value: "Puducherry", label: "Puducherry (PNY)" },
    { value: "Rourkela", label: "Rourkela (RRK)" },
    { value: "Tuticorin", label: "Tuticorin (TCR)" },
    { value: "Aurangabad", label: "Aurangabad (IXU)" },
    { value: "Bathinda", label: "Bathinda (BUP)" },
    { value: "Bokaro", label: "Bokaro (IXR)" },
    { value: "Chikkamagaluru", label: "Chikkamagaluru (IXG)" },
    { value: "Dimapur", label: "Dimapur (DMU)" },
    { value: "Gaya", label: "Gaya (GAY)" },
    { value: "Jhansi", label: "Jhansi (JHN)" },
    { value: "Kadapa", label: "Kadapa (CDP)" },
    { value: "Kolhapur", label: "Kolhapur (KLH)" },
    { value: "Lakhimpur", label: "Lakhimpur (LH" },
    { value: "Lilabari", label: "Lilabari (IXI)" },
    { value: "Mangalore", label: "Mangalore (IXE)" },
    { value: "Mysore", label: "Mysore (MYQ)" },
    { value: "Nashik", label: "Nashik (ISK)" },
    { value: "Neyveli", label: "Neyveli (NVY)" },
    { value: "Pasighat", label: "Pasighat (IXT)" },
    { value: "Pathankot", label: "Pathankot (IXP)" },
    { value: "Puducherry", label: "Puducherry (PNY)" },
    { value: "Rajahmundry", label: "Rajahmundry (RJA)" },
    { value: "Rupsi", label: "Rupsi (RUP)" },
    { value: "Salem", label: "Salem (SXV)" },
    { value: "Shirdi", label: "Shirdi (SAG)" },
    { value: "Tirupati", label: "Tirupati (TIR)" },
    { value: "Vidyanagar", label: "Vidyanagar (VDY)" },
    { value: "Vijayawada", label: "Vijayawada (VGA)" },
    { value: "Kannur", label: "Kannur (CNN)" },
    { value: "Gwalior", label: "Gwalior (GWL)" },
    { value: "Kandla", label: "Kandla (IXY)" },
    { value: "Porbandar", label: "Porbandar (PBD)" },
    { value: "Bhuntar", label: "Bhuntar (KUU)" },
    { value: "Panna", label: "Panna (PNY)" },
    { value: "Birsa Munda", label: "Birsa Munda (IXR)" },
    { value: "Jharsuguda", label: "Jharsuguda (JRG)" },
    { value: "Bhuj", label: "Bhuj (BHJ)" },
    { value: "Kangra", label: "Kangra (DHM)" },
    { value: "Allahabad", label: "Allahabad (IXD)" },
    { value: "Jabalpur", label: "Jabalpur (JLR)" },
    { value: "Nagaur", label: "Nagaur (NAG)" },
    { value: "Jamshedpur", label: "Jamshedpur (IXW)" },
    { value: "Vidyanagar", label: "Vidyanagar (VDY)" },
    { value: "Sindhudurg", label: "Sindhudurg (SDR)" },
    { value: "Bilaspur", label: "Bilaspur (PAB)" },
    { value: "Gondia", label: "Gondia (GDA)" },
    { value: "Jorhat", label: "Jorhat (JRH)" },
    { value: "Jagdalpur", label: "Jagdalpur (JGB)" },
    { value: "Kalaburagi", label: "Kalaburagi (GBI)" },
    { value: "Los Angeles", label: "Los Angeles (LAX)" },
    { value: "New York City", label: "New York City (JFK)" },
    { value: "Chicago", label: "Chicago (ORD)" },
    { value: "Miami", label: "Miami (MIA)" },
    { value: "Chicago", label: "Chicago (ORD)" },
    { value: "San Francisco", label: "San Francisco (SFO)" },
    { value: "Las Vegas", label: "Las Vegas (LAS)" },
    { value: "Dallas/Fort Worth", label: "Dallas/Fort Worth (DFW)" },
    { value: "Atlanta", label: "Atlanta (ATL)" },
    { value: "London", label: "London (LHR)" },
    { value: "Manchester", label: "Manchester (MAN)" },
    { value: "Edinburgh", label: "Edinburgh (EDI)" },
    { value: "Toronto", label: "Toronto (YYZ)" },
    { value: "Vancouver", label: "Vancouver (YVR)" },
    { value: "Montreal", label: "Montreal (YUL)" },
    { value: "Sydney", label: "Sydney (SYD)" },
    { value: "Melbourne", label: "Melbourne (MEL)" },
    { value: "Brisbane", label: "Brisbane (BNE)" },
    { value: "Perth", label: "Perth (PER)" },
    { value: "Dubai", label: "Dubai (DXB)" },
    { value: "Abu Dhabi", label: "Abu Dhabi (AUH)" },
    { value: "Frankfurt", label: "Frankfurt (FRA)" },
    { value: "Munich", label: "Munich (MUC)" },
    { value: "Berlin", label: "Berlin (TXL)" },
    { value: "Paris", label: "Paris (CDG)" },
    { value: "Nice", label: "Nice (NCE)" },
    { value: "Singapore", label: "Singapore (SIN)" },
    { value: "Hong Kong", label: "Hong Kong (HKG)" },
    { value: "Tokyo", label: "Tokyo (NRT)" },
    { value: "Seoul", label: "Seoul (ICN)" },
    { value: "Beijing", label: "Beijing (PEK)" },
    { value: "Shanghai", label: "Shanghai (PVG)" },
    { value: "Bangkok", label: "Bangkok (BKK)" },
    { value: "Phuket", label: "Phuket (HKT)" },
    { value: "Madrid", label: "Madrid (MAD)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Rome", label: "Rome (FCO)" },
    { value: "Milan", label: "Milan (MXP)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Zurich", label: "Zurich (ZRH)" },
    { value: "Geneva", label: "Geneva (GVA)" },
    { value: "São Paulo", label: "São Paulo (GRU)" },
    { value: "Rio de Janeiro", label: "Rio de Janeiro (GIG)" },
    { value: "Johannesburg", label: "Johannesburg (JNB)" },
    { value: "Cape Town", label: "Cape Town (CPT)" },
    { value: "Mexico City", label: "Mexico City (MEX)" },
    { value: "Cancun", label: "Cancun (CUN)" },
    { value: "Dublin", label: "Dublin (DUB)" },
    { value: "London", label: "London (LHR)" },
    { value: "Paris", label: "Paris (CDG)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Cairo", label: "Cairo (CAI)" },
    { value: "Athens", label: "Athens (ATH)" },
    { value: "Moscow", label: "Moscow (SVO)" },
    { value: "St. Petersburg", label: "St. Petersburg (LED)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Ankara", label: "Ankara (ESB)" },
    { value: "Lisbon", label: "Lisbon (LIS)" },
    { value: "Porto", label: "Porto (OPO)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Cape Town", label: "Cape Town (CPT)" },
    { value: "Mexico City", label: "Mexico City (MEX)" },
    { value: "Cancun", label: "Cancun (CUN)" },
    { value: "Dublin", label: "Dublin (DUB)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Cairo", label: "Cairo (CAI)" },
    { value: "Athens", label: "Athens (ATH)" },
    { value: "Moscow", label: "Moscow (SVO)" },
    { value: "St. Petersburg", label: "St. Petersburg (LED)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Ankara", label: "Ankara (ESB)" },
    { value: "Lisbon", label: "Lisbon (LIS)" },
    { value: "Porto", label: "Porto (OPO)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Cape Town", label: "Cape Town (CPT)" },
    { value: "Mexico City", label: "Mexico City (MEX)" },
    { value: "Cancun", label: "Cancun (CUN)" },
    { value: "Dublin", label: "Dublin (DUB)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Cairo", label: "Cairo (CAI)" },
    { value: "Athens", label: "Athens (ATH)" },
    { value: "Moscow", label: "Moscow (SVO)" },
    { value: "St. Petersburg", label: "St. Petersburg (LED)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Ankara", label: "Ankara (ESB)" },
    { value: "Lisbon", label: "Lisbon (LIS)" },
    { value: "Porto", label: "Porto (OPO)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Cape Town", label: "Cape Town (CPT)" },
    { value: "Mexico City", label: "Mexico City (MEX)" },
    { value: "Cancun", label: "Cancun (CUN)" },
    { value: "Dublin", label: "Dublin (DUB)" },
    { value: "London", label: "London (LHR)" },
    { value: "Paris", label: "Paris (CDG)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Cairo", label: "Cairo (CAI)" },
    { value: "Athens", label: "Athens (ATH)" },
    { value: "Moscow", label: "Moscow (SVO)" },
    { value: "St. Petersburg", label: "St. Petersburg (LED)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Ankara", label: "Ankara (ESB)" },
    { value: "Lisbon", label: "Lisbon (LIS)" },
    { value: "Porto", label: "Porto (OPO)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Cape Town", label: "Cape Town (CPT)" },
    { value: "Mexico City", label: "Mexico City (MEX)" },
    { value: "Cancun", label: "Cancun (CUN)" },
    { value: "Dublin", label: "Dublin (DUB)" },
    { value: "London", label: "London (LHR)" },
    { value: "Paris", label: "Paris (CDG)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Cairo", label: "Cairo (CAI)" },
    { value: "Athens", label: "Athens (ATH)" },
    { value: "Moscow", label: "Moscow (SVO)" },
    { value: "St. Petersburg", label: "St. Petersburg (LED)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Ankara", label: "Ankara (ESB)" },
    { value: "Lisbon", label: "Lisbon (LIS)" },
    { value: "Newark", label: "Newark (EWR)" },
    { value: "Kuala Lumpur", label: "Kuala Lumpur (KUL)" },
    { value: "Porto", label: "Porto (OPO)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Cape Town", label: "Cape Town (CPT)" },
    { value: "Mexico City", label: "Mexico City (MEX)" },
    { value: "Cancun", label: "Cancun (CUN)" },
    { value: "Dublin", label: "Dublin (DUB)" },
    { value: "London", label: "London (LHR)" },
    { value: "Paris", label: "Paris (CDG)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Cairo", label: "Cairo (CAI)" },
    { value: "Athens", label: "Athens (ATH)" },
    { value: "Moscow", label: "Moscow (SVO)" },
    { value: "St. Petersburg", label: "St. Petersburg (LED)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Ankara", label: "Ankara (ESB)" },
    { value: "Lisbon", label: "Lisbon (LIS)" },
    { value: "Porto", label: "Porto (OPO)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Cape Town", label: "Cape Town (CPT)" },
    { value: "Mexico City", label: "Mexico City (MEX)" },
    { value: "Cancun", label: "Cancun (CUN)" },
    { value: "Dublin", label: "Dublin (DUB)" },
    { value: "London", label: "London (LHR)" },
    { value: "Paris", label: "Paris (CDG)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Cairo", label: "Cairo (CAI)" },
    { value: "Athens", label: "Athens (ATH)" },
    { value: "Moscow", label: "Moscow (SVO)" },
    { value: "St. Petersburg", label: "St. Petersburg (LED)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Ankara", label: "Ankara (ESB)" },
    { value: "Lisbon", label: "Lisbon (LIS)" },
    { value: "Porto", label: "Porto (OPO)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Cape Town", label: "Cape Town (CPT)" },
    { value: "Mexico City", label: "Mexico City (MEX)" },
    { value: "Cancun", label: "Cancun (CUN)" },
    { value: "Dublin", label: "Dublin (DUB)" },
    { value: "London", label: "London (LHR)" },
    { value: "Paris", label: "Paris (CDG)" },
    { value: "Amsterdam", label: "Amsterdam (AMS)" },
    { value: "Barcelona", label: "Barcelona (BCN)" },
    { value: "Cairo", label: "Cairo (CAI)" },
    { value: "Athens", label: "Athens (ATH)" },
    { value: "Moscow", label: "Moscow (SVO)" },
    { value: "St. Petersburg", label: "St. Petersburg (LED)" },
    { value: "Istanbul", label: "Istanbul (IST)" },
    { value: "Ankara", label: "Ankara (ESB)" },
    { value: "Lisbon", label: "Lisbon (LIS)" },
    { value: "Porto", label: "Porto (OPO)" },
    { value: "Lima", label: "Lima (LIM)" },
    { value: "Buenos Aires", label: "Buenos Aires (EZE)" },
    { value: "Santiago", label: "Santiago (SCL)" },
    { value: "Macau", label: "Macau (MFM)" },
    { value: "Leh", label: "Leh (IXL)" },
    { value: "Port Blair", label: "Port Blair (IXZ)" },
    { value: "Bagdogra", label: "Bagdogra (IXB)" },
    { value: "Pagadian", label: "Pagadian (PYG)" },
    { value: "Udaipur", label: "Udaipur (UDR)" }


]


export default cities;
import './App.css';
import Routes from './route/Routes';
function App() {
  return (
    <>
    <Routes />
    </>
  );
}

export default App;

import React from 'react';
import './article.css';

const Article = ({imgUrl,Place,text, days, night}) => {
  return (
    <div className='gpt3__blog-container_article'>
      <div className="gpt3__blog-container_article-image">
        <img src={imgUrl} alt="blog_image" />
      </div>
        <div className="gpt3__blog-container_article-content">
          <div>
            <p>{Place}</p>
            <ul>
            <li>
              {days} Day / {night} Night Stay
            </li>
            <li>
              Best Hotels to Stay.
            </li>
          </ul>
          </div>
          
        </div>
    </div>
  )
}

export default Article

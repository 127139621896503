import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Booking.css";
import bookingImg from "./images/image";
import cities from "./cities";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Offers from "../offers/Offers";
import Whyus from "../whyus/Whyus";
import Services from "../services/Services";
import Header from "../header/Header";
import link from '../../apiCall';


const Booking = () => {
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);


  const handleOriginCity = (selectedOption) => {
    setOrigin(selectedOption);
  };
  const handleDestinationCity = (selectedOption) => {
    setDestination(selectedOption);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(null);
  const currentDate = new Date();

  const handleDateChange = (date) => {
    setSelectedDate(date);

  };
  const handleReturnDate = (date) => {
    setReturnDate(date);
  };

  // console.log(origin ? origin.value : "None");


  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "180px",
      border: "none",
      cursor: "pointer",
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };

  const [isOriginDropdownOpen, setIsOriginDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleOriginDropdown = () => {
    setIsOriginDropdownOpen(!isOriginDropdownOpen);
  };


  const [isDestinationDropdownOpen, setIsDestinationDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDestinationDropdown = () => {
    setIsDestinationDropdownOpen(!isDestinationDropdownOpen);
  };
  //--------------------

  const [travellers, setTravellers] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  //-----------------

  const [travellersError, setTravellersError] = useState('');
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateTravellers = () => {
    const parsedValue = parseInt(travellers, 10);

    if (isNaN(parsedValue) || parsedValue < 1 || parsedValue >= 15) {
      setTravellersError('Please enter a valid number between 1 and 14');
    } else {
      setTravellersError('');
    }
  };

  const validatePhone = () => {
    const phoneRegex = /^\d{10}$/;

    if (!phone.match(phoneRegex)) {
      setPhoneError("Please enter a valid 10-digit mobile number");
    } else {
      setPhoneError("");
    }
  };

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("Please enter your name");
    } else {
      setNameError("");
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.match(emailRegex)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };


  //---------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateTravellers();
    validatePhone();
    validateName();
    validateEmail();

    const depDate = selectedDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });
    const retDate = returnDate ? returnDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' }) : '';

    console.log(JSON.stringify({
      origin, destination, depDate, retDate, travellers,
      phone, name, email
    }));


    try {
      const response = await fetch(`${link}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          origin, destination, depDate, retDate, travellers,
          phone, name, email
        })
      });
      console.log(response);
      const data = await response.json();
      if (response.status === 400 || !response) {
        toast.error(data.message)
        console.log("No Registered")
      }
      else {
        toast.success(data.message)

        console.log("Yes Registered")
      }
    }
    catch (error) {
      console.error("Error during form submission:", error);
      toast.error("An error occurred during submission.");
    }
    finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>

      <div className="tw__home">
        <ToastContainer />
        {/* <div className="quote_container">
        <img src={bookingImg.quote} alt="No Quote" />
        <p>"Take Flight with Savings! ✈️ Book Your Next Adventure Today!"</p>
      </div> */}
        <Header quote="Take Flight with Savings! ✈️ Book Your Next Adventure Today!" />
        <div className="image_container">
          <img src={bookingImg.plane} alt="No Plane" />
        </div>
        <div className="tw__home_form">
          <form onSubmit={handleSubmit} className="tw__home_form-container">

            <div className="tw__home_form_inputs">
              <div className="origin" onClick={toggleOriginDropdown}>
                <label className="label_booking">From</label>
                <Select
                  className="tw__select_origin"
                  value={origin}
                  onChange={handleOriginCity}
                  options={cities}
                  placeholder="Click to select City"
                  styles={customStyles}
                  menuIsOpen={isOriginDropdownOpen}
                  onClick={toggleOriginDropdown}
                />
              </div>

              <div className="destination" onClick={toggleDestinationDropdown}>
                <label className="label_booking">To</label>
                <Select
                  className="tw__select_destination"
                  value={destination}
                  onChange={handleDestinationCity}
                  options={cities}
                  placeholder="Click to select City"
                  styles={customStyles}
                  menuIsOpen={isDestinationDropdownOpen}
                  onClick={toggleDestinationDropdown}
                />
              </div>

              <div className="date_departure">
                <label className="label_booking">Departure</label>
                <DatePicker
                  className="date-picker"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  minDate={currentDate}
                  customInput={
                    <div className="custom-input1">
                      <div className="date_booking">
                        {selectedDate && selectedDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric' })}
                      </div>
                      <div className="day_booking">
                        {selectedDate && selectedDate.toLocaleDateString('default', { weekday: 'short' })}
                      </div>
                    </div>
                  }
                />
              </div>


              <div className="date_return">
                <label className="label_booking">Return</label>
                <DatePicker
                  className="date-picker"
                  selected={returnDate}
                  onChange={handleReturnDate}
                  dateFormat="dd/MM/yyyy"
                  minDate={currentDate}
                  customInput={
                    <div className="custom-input2">
                      {returnDate ? (
                        <React.Fragment>
                          <div className="date_booking">
                            {returnDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric' })}
                          </div>
                          <div className="day_booking">
                            {returnDate.toLocaleDateString('default', { weekday: 'short' })}
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="placeholder">
                          Tap to enter Date
                        </div>
                      )}
                    </div>
                  }
                // placeholderText="Click to enter Date"
                />
              </div>

              <div className="travellers">
                <label className="label_booking">Travellers</label>
                <input
                  type="text"
                  name="travellers"
                  id="travellers"
                  placeholder="1"
                  value={travellers}
                  onChange={(e) => setTravellers(e.target.value)}
                  onBlur={validateTravellers}
                  className={travellersError ? 'errorTravellers' : ''}
                  autoComplete="off"
                />
                {travellersError && <p className="error-messageTravellers">{travellersError}</p>}
              </div>

              <div className="phone">
                <label className="label_booking">Mobile No.</label>
                <input
                  type="text"
                  name="number"
                  id="number"
                  placeholder="9999999999"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onBlur={validatePhone}
                  className={phoneError ? "errorPhone" : ""}
                />
                {phoneError && <p className="error-messagePhone">{phoneError}</p>}
              </div>
              <div className="name">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your Name Here"
                  value={name}
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                  onBlur={validateName}
                  className={nameError ? "errorName" : ""}
                />
                {nameError && <p className="error-messageName">{nameError}</p>}
              </div>
              <div className="email">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email Here"
                  value={email}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={validateEmail}
                  className={emailError ? "errorName" : ""}
                />
                {emailError && <p className="error-messageName">{emailError}</p>}
              </div>
            </div>
            <div className="btn">
              <button
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Offers />
      <Whyus />
      <Services />
    </>
  );
};

export default Booking;
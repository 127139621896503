import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg';
import specialOffers from './specialOffer.png'

const OffersImg = {
    img1,
    img2,
    img3,
    specialOffers
};

export default OffersImg;
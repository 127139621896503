import React from "react";

const Aboutus = () => {
  return (
    <div className="section__padding">
      <p className="p__opensans" style={{ color: "black", marginTop: "1rem" }}>
        With a rich history spanning over a decade, our travel agency stands as
        a beacon of excellence in the world of travel and tourism. We have
        meticulously crafted memorable journeys for countless travelers, earning
        their trust and loyalty along the way.
        </p>
        <p className="p__opensans" style={{ color: "black", marginTop: "1rem" }}>
          Our portfolio of services encompasses every facet of your travel
          needs. Whether you're planning a domestic escape or an international
          adventure, our seasoned experts are at your service to secure the best
          flight options, ensuring convenience and affordability. We take pride
          in our ability to turn your travel dreams into a reality.
        </p>
        <p className="p__opensans" style={{ color: "black", marginTop: "1rem" }}>
          But our commitment doesn't stop there. We understand that where you
          stay is just as crucial as how you get there. That's why we offer
          comprehensive hotel booking services, with a keen eye for comfort,
          luxury, and value for money. Our holiday packages are carefully
          designed to provide unique and unforgettable experiences, tailored to
          your preferences and desires.
        </p>
        <p className="p__opensans" style={{ color: "black", marginTop: "1rem" }}>
          For those venturing abroad, our visa services streamline the often
          complex and daunting process, ensuring you have all the necessary
          documentation for a smooth international journey.
        </p>
        <p className="p__opensans" style={{ color: "black", marginTop: "1rem" }}>
          At our core, we are driven by a passion for travel and a dedication to
          customer satisfaction. Our team's expertise and unwavering commitment
          to excellence have been the cornerstone of our success over the years.
          When you choose our agency, you're not just booking a trip; you're
          embarking on a journey filled with personalized care and attention to
          detail.
        </p>
        <p className="p__opensans" style={{ color: "black", marginTop: "1rem" }}>
          Join us, and let's make your next adventure the best one yet. Travel
          with us, where every journey is a story worth sharing.
        </p>
      
    </div>
  );
};

export default Aboutus;

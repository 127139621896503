import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Vacations.css";
import cities from "./cities";
import budget from "./budget";
import Header from "../header/Header";
import Packageoffers from '../packageoffers/Packageoffers';
import Offers from "../offers/Offers";
import Whyus from "../whyus/Whyus";
import Services from "../services/Services";
import link from '../../apiCall';

const Home = () => {
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);


  const handleOriginCity = (selectedOption) => {
    setOrigin(selectedOption);
  };
  const handleDestinationCity = (selectedOption) => {
    setDestination(selectedOption);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(null);
  const currentDate = new Date();

  const handleDateChange = (date) => {
    setSelectedDate(date);

  };
  const handleReturnDate = (date) => {
    setReturnDate(date);
  };

  // console.log(origin ? origin.value : "None");

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "170px",
      border: "none",
      cursor: "pointer",
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };

  const [isOriginDropdownOpen, setIsOriginDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleOriginDropdown = () => {
    setIsOriginDropdownOpen(!isOriginDropdownOpen);
  };

  const [isDestinationDropdownOpen, setIsDestinationDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDestinationDropdown = () => {
    setIsDestinationDropdownOpen(!isDestinationDropdownOpen);
  };

  const [money, setMoney] = useState(null);
  const handleMoneyCity = (selectedOption) => {
    setMoney(selectedOption);
  };

  const [isMoneyDropdownOpen, setIsMoneyDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleMoneyDropdown = () => {
    setIsMoneyDropdownOpen(!isMoneyDropdownOpen);
  };
  //--------------------

  // const [travellers, setTravellers] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  //---------------

  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const validatePhone = () => {
    const phoneRegex = /^\d{10}$/;

    if (!phone.match(phoneRegex)) {
      setPhoneError("Please enter a valid 10-digit mobile number");
    } else {
      setPhoneError("");
    }
  };

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("Please enter your name");
    } else {
      setNameError("");
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.match(emailRegex)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };


  //---------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    validatePhone();
    validateName();
    validateEmail();


    const depDate = selectedDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' });
    const retDate = returnDate ? returnDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' }) : '';


    console.log(JSON.stringify({
      origin, destination, depDate, retDate, money,
      phone, name, email
    }));


    try {
      const response = await fetch(`${link}/vacations`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          origin, destination, depDate, retDate, money,
          phone, name, email
        })
      });
      console.log(response);
      const data = await response.json();
      if (response.status === 400 || !response) {
        toast.error(data.message)
        console.log("No Registered")
      }
      else {
        toast.success(data.message)
        
        console.log("Yes Registered")
      }
    }
    catch (error) {
      console.error("Error during form submission:", error);
      toast.error("An error occurred during submission.");
    }
    finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <div className="tw__vacation">

        <Header quote="Don't just dream it, live it - your dream vacation awaits!" />

        <div className="tw__vacation_form">
          <form onSubmit={handleSubmit} className="tw__vacation_form-container">
            <ToastContainer />
            <div className="tw__vacation_form_inputs">
              <div className="origin_vacation" onClick={toggleOriginDropdown}>
                <label className="label_vacation">Origin</label>
                <Select
                  className="tw__vacation_select_origin"
                  value={origin}
                  onChange={handleOriginCity}
                  options={cities}
                  placeholder="Click to select City"
                  styles={customStyles}
                  menuIsOpen={isOriginDropdownOpen}
                  onClick={toggleOriginDropdown}
                />
              </div>

              <div className="destination_vacation" onClick={toggleDestinationDropdown}>
                <label className="label_vacation">Destination</label>
                <Select
                  className="tw__vacation_select_destination"
                  value={destination}
                  onChange={handleDestinationCity}
                  options={cities}
                  placeholder="Click to select City"
                  styles={customStyles}
                  menuIsOpen={isDestinationDropdownOpen}
                  onClick={toggleDestinationDropdown}
                />
              </div>

              <div className="date_departure_vacation">
                <label className="label_vacation">Departure</label>
                <DatePicker
                  className="date-picker"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  minDate={currentDate}
                  customInput={
                    <div className="custom-input1_vacation">
                      <div className="date">
                        {selectedDate && selectedDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric' })}
                      </div>
                      <div className="day">
                        {selectedDate && selectedDate.toLocaleDateString('default', { weekday: 'short' })}
                      </div>
                    </div>
                  }
                />
              </div>


              <div className="date_return_vacation">
                <label className="label_vacation">Return</label>
                <DatePicker
                  className="date-picker"
                  selected={returnDate}
                  onChange={handleReturnDate}
                  dateFormat="dd/MM/yyyy"
                  minDate={currentDate}
                  customInput={
                    <div className="custom-input2_vacation">
                      {returnDate ? (
                        <React.Fragment>
                          <div className="date">
                            {returnDate.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric' })}
                          </div>
                          <div className="day">
                            {returnDate.toLocaleDateString('default', { weekday: 'short' })}
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="placeholder_vacation">
                          Tap to enter Date
                        </div>
                      )}
                    </div>
                  }

                />
              </div>

              <div className="budget_vacation" onClick={toggleMoneyDropdown}>
                <label className="label_vacation">Budget</label>
                <Select
                  className="tw__vacation_select_budget"
                  value={money}
                  onChange={handleMoneyCity}
                  options={budget}
                  placeholder="Select the Budget"
                  styles={customStyles}
                  menuIsOpen={isMoneyDropdownOpen}
                  onClick={toggleMoneyDropdown}
                />

              </div>

              <div className="phone_vacation">
                <label className="label_vacation">Mobile No.</label>
                <input
                  type="text"
                  name="number"
                  id="number"
                  placeholder="9999999999"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onBlur={validatePhone}
                  className={phoneError ? "errorPhone" : ""}
                />
                {phoneError && <p className="error-messagePhone">{phoneError}</p>}
              </div>
              <div className="name_vacation">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your Name Here"
                  value={name}
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                  onBlur={validateName}
                  className={nameError ? "errorName" : ""}
                />
                {nameError && <p className="error-messageName">{nameError}</p>}
              </div>
              <div className="email_vacation">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email Here"
                  value={email}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={validateEmail}
                  className={emailError ? "errorName" : ""}
                />
                {emailError && <p className="error-messageName">{emailError}</p>}
              </div>
            </div>
            <div className="btn_vacation">
              <button 
                type="submit" 
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Packageoffers />
      <Offers />
      <Whyus />
      <Services />
    </>
  );
};

export default Home;
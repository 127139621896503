import React, {useState} from 'react'
import  './contactus.css'
import { FaEnvelope } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';

import link from '../../apiCall';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contactus = () => {

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [textarea, setTextarea] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(JSON.stringify({
            fname,
            lname,
            email,
            phone,
            textarea,
          }));
      
          try {
            const response = await fetch(`${link}/contact`, {
              method: 'POST',
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ 
                fname,
            lname,
            email,
            phone,
            textarea,
              })
            });
            console.log(response);
            if (response.status === 422 || !response) {
              toast.error("Invalid Registration")
              console.log("No Registered")
            }
            else {
              toast.success("Success Registration")
              
              console.log("Yes Registered")
              
            }
          }
          catch (error) {
            console.error("Error during form submission:", error);
            toast.error("An error occurred during submission.");
          }
    }

  return (
    <section className="section-wrapper" id="#contactus">   
        <div className="box-wrapper section__padding">
            <ToastContainer />
            <div className="info-wrap">
                <h2 className="info-title">Contact Information</h2>
                <h3 className="info-sub-title">Fill up the form and our Team will get back to you within 24 hours</h3>
                <ul className="info-details">
                    <li>
                        <BsFillTelephoneFill className='icon' fontSize={18}/>
                        <span>Phone:</span> <a href="tel:+ 1235 2355 98">+91 8824284772</a>
                    </li>
                    <li>
                        <FaEnvelope className='icon' fontSize={18}/>
                        <span>Email:</span> <a href="mailto:info@yoursite.com">travelwingsreserve@gmail.com</a>
                    </li>
                </ul>
                <ul className="social-icons">
                    <li><Link to="#"> <FaSquareFacebook className='icons' /> </Link></li>
                    <li><Link to="https://www.instagram.com/travelwingsindia/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"> <FaSquareInstagram className='icons' /></Link></li>
                    <li><Link to="#"> <FaSquareTwitter className='icons' /></Link></li>
                </ul>
            </div>
            <div className="form-wrap">
                <form onSubmit={handleSubmit} method="POST">
                    <h2 className="form-title">Send us a message</h2>
                    <div className="form-fields">
                        <div className="form-group">
                            <input 
                                type="text" 
                                className="fname" 
                                placeholder="First Name" 
                                value={fname} 
                                onChange={(e) => setFname(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input 
                            type="text" 
                            className="lname" 
                            placeholder="Last Name" 
                            value={lname} 
                            onChange={(e) => setLname(e.target.value)}
                        />
                        </div>
                        <div className="form-group">
                            <input 
                            type="email" 
                            className="email" 
                            placeholder="Mail" 
                            value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                        />
                        </div>
                        <div className="form-group">
                            <input 
                            type="text" 
                            className="phone" 
                            placeholder="Phone" 
                            value={phone} 
                                onChange={(e) => setPhone(e.target.value)}
                        />
                        </div>
                        <div className="form-group">
                            <textarea 
                                name="message" 
                                placeholder="Write your message" 
                                value={textarea} 
                                onChange={(e) => setTextarea(e.target.value)}
                            />
                        </div>
                    </div>
                    <input type="submit" value="Send Message" className="submit-button" />
                </form>
            </div>
        </div>
    </section>
  )
}

export default Contactus

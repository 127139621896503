import React from 'react'
import './offers.css';
import { OffersImg } from '../../assets/offersImg/'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

const Offers = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };
  return (
    <div className="app__offers flex__center" id='offers'>
      <div className="app__offers-content">
        <img src={OffersImg.specialOffers} alt="Special Offers" className='app__offers-content_img'/>
        <h3 className="headtext">Your Ticket to Affordable Journey!</h3>
        <p className="p__opensans" style={{ color: '#000', marginTop: '0.5rem' }}>Book your ticket and avail exciting offers and discounts. </p>
        <button type="button" className="custom__button">View All</button>
      </div>
      <div className="app__offers-images">
        <div className="app__offers-images_container" ref={scrollRef}>
          {[OffersImg.img1, OffersImg.img2, OffersImg.img3].map((image, index) => (
            <div className="app__offers-images_card flex__center" key={`offers_image-${index + 1}`}>
              <img src={image} alt="offers_image" />
            </div>
          ))}
        </div>
        <div className="app__offers-images_arrows">
          <BsArrowLeftShort className="offers__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="offers__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  )
}

export default Offers

const options = [{
        id: 'Option1',
        heading: 'Flights',
        content: 'Fly confidently with Travelwings. We offer extensive domestic and international flights, prioritizing safety, comfort, and memorable travel experiences. Join us today for seamless journeys!',
    },
    {
        id: 'Option2',
        heading: 'Holiday Packages',
        content: 'Escape with Travelwings! Our holiday packages promise unforgettable domestic and international adventures, handpicked accommodations, and expert planning. Your dream holiday starts here with us.',
    },
    {
        id: 'Option3',
        heading: 'Hotels',
        content: 'Streamline your travel plans on our website. Browse and book hotels effortlessly with diverse options, competitive rates, and secure reservations. Your journey begins here.',
    },
    {
        id: 'Option4',
        heading: 'Visa',
        content: 'Unlock global opportunities with our visa services. We simplify the application process, providing expert guidance and support to ensure your travel aspirations become a reality. Explore the world with confidence.',
    },
];

export default options;